<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Nieuwe organisatie
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newOrganization.title.value"
                  :error-messages="newOrganization.title.errors"
                  autofocus
                  label="Naam"
                  @blur="validateTitle"
                  @input="newOrganization.title.errors = []"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="validateAndCreate"
                >
                  Opslaan
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      newOrganization: {
        title: {
          value: '',
          errors: [],
        },
        lastName: {
          value: '',
          errors: [],
        },
        email: {
          value: '',
          errors: [],
        },
        organizationId: {
          value: null,
          errors: [],
        },
        type: {
          value: 'practitioners',
          errors: [],
        },
      },
      breadcrumbs: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Organisaties',
          disabled: false,
          exact: true,
          to: { name: 'organizations' },
        },
        {
          text: 'Nieuwe organisatie',
          disabled: true,
          exact: true,
          to: { name: 'organizationsNew' },
        },
      ],
    };
  },
  methods: {
    createOrganization() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations`;

      this.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: {
          title: this.newOrganization.title.value,
        },
        method: 'post',
      })
        .then((response) => {
          console.log(response.data);
          this.$router.push({ name: 'organizations' });
        });
    },
    validateTitle() {
      this.newOrganization.title.value = this.newOrganization.title.value.trim();

      const newErrors = [];
      if (!this.newOrganization.title.value) {
        newErrors.push('Is verplicht');
      }

      this.newOrganization.title.errors = newErrors;
    },
    validateAndCreate() {
      this.validateTitle();

      if (this.newOrganization.title.errors.length > 0) return;

      this.createOrganization();
    },
  },
};
</script>
